@import 'vars';
@import 'mixins';

.nav-tabs {
  margin-top: 15px;

  @include media_for(desktop) {
    margin-top: 30px;
  }

  .nav-item {
    a {
      border-radius: 0;
      font-size: 8px;
      color: $c-neutral-40;
      background: transparent;
      border: 0.5px solid $c-neutral-20;
      border-right-width: 0;
      width: 135px;
      padding: 0;
      text-align: center;
      height: 30px;
      line-height: 28px;

      @include media_for(desktop) {
        font-size: 12px;
        width: 180px;
        height: 60px;
        line-height: 60px;
      }

      &:hover {
        border: 0.5px solid $c-neutral-20;
        border-right-width: 0;
      }

      &.active {
        background: $c-white;
        color: $c-neutral-black;
        border: 0.5px solid $c-neutral-20;
        border-right-width: 0;
        position: relative;
        font-weight: bold;

        &:before {
          @include abs_center(100%, 3px);
          content: '';
          background: $c-primary-medium-dark-blue;
          bottom: inherit;
        }
      }

      div {
        background: $c-primary-medium-dark-blue;
        margin-right: 5px;
        height: 13px;
        text-align: center;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        color: $c-white;
        line-height: 13px;
        border-radius: 10px;

        @include media_for(desktop) {
          height: 15px;
          line-height: 16px;
          top: -1px;
        }

        span {
          font-weight: normal;
          padding: 2px 4px;
        }
      }
    }

    &:last-of-type {
      a {
        border-right-width: 0.5px;
      }
    }
  }
}
