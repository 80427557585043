$transition: 0.5s ease-in-out;

// Colors
$c-dark-gray: #4A4F54;
$c-dark-blue: #2C363E;
$c-deep-blue: #20262B;
$c-primary-soft-orange: #FDBA61;
$c-primary-orange: #F18A00;
$c-primary-soft-green: #BFDB8F;
$c-primary-light-green: #8EC640;
$c-primary-medium-green: #1E5634;
$c-primary-medium-black: #231F20;
$c-primary-medium-dark-blue: #12284C;
$c-blue: #67BFC6;
$c-white: #ffffff;
$c-neutral-off-white: #F1F2F2;
$c-neutral-grey: #414042;
$c-neutral-black: #231F20;
$c-neutral-20: #D1D3D4;
$c-neutral-60: #808285;
$c-neutral-40: #B0B0B0;
$c-red: #dc3545;
$c-dark-green:#13301D;
$bg-1:#F08A08;
$bg-2:#FBC885;
$bg-3:#BED1F0;
$bg-4:#6491DA;
$bg-5:#1D4079;

// Fonts
$f-rr: 'Roboto-Regular';
$f-rb: 'Roboto-Bold';
$f-rm: 'Roboto-Medium';
