@import 'vars';


.p-sortable-column-icon {
  color: $c-primary-medium-dark-blue !important;
}


// Add Icon (+)
.new {
  .fa-plus-circle {
    color: $c-primary-medium-dark-blue !important;
    font-size: 22px;
  }
}