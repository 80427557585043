@import 'vars';

.bg-s-orange, .bg-2 {
  background: $bg-2;
  color: $c-white;
}

.bg-orange, .bg-1 {
  background: $bg-1;
  color: $c-white;
}

.bg-s-green, .bg-3 {
  background: $bg-3;
  color: $c-white;
}

.bg-l-green, .bg-4 {
  background: $bg-4;
  color: $c-white;
}

.bg-5 {
  background: $bg-5;
  color: $c-white;
}
.bg-green{
  background: #5E643A;
  color: $c-white;
}

.bg-neutral-40 {
  background: $c-neutral-40;
}
