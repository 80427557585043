@import 'vars';

.p-datepicker {
  background: $c-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid $c-neutral-20;
  top: 40px !important;

  button {
    //width: 20px;
    //height: 20px;
    //border-radius: 100%;
    //transition: $transition;
    //position: relative;
   // border: 1px solid $c-primary-medium-dark-blue;
   // outline: none;

    &:hover {
      background: $c-primary-medium-dark-blue;

      &:after {
        color: $c-white;
      }
    }

    &:after {
      @include abs_center(100%, 100%);
      //content: '';
      left: -2px;
      font-weight: bold;
      font-size: 11px;
      color: $c-primary-medium-dark-blue;
    }

    &.p-datepicker-next:after {
     // content: '';
      right: -4px;
    }
  }

  .p-datepicker-header {
    padding: 15px;

    .p-datepicker-title {
      .p-datepicker-month:focus, .p-datepicker-year:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $c-neutral-20 !important;
        border-color: $c-neutral-20 !important;
      }
    }

    .p-datepicker-prev:focus, .p-datepicker-next:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $c-neutral-20 !important;
    }


  }

  .p-datepicker-title {
    span {
      margin: auto 5px;
    }

    select {
      border: 0px;
      margin: auto 5px;
    }
  }

  .p-highlight {
    background: $c-primary-medium-dark-blue;
    color: $c-white;
  }

  .p-disabled {
    color: $c-neutral-20;
    cursor: initial;
  }

  .p-datepicker-buttonbar {

    & > .p-button, .p-button-text {
      color: $c-primary-medium-dark-blue !important;
    }

    button {
      width: auto;
      margin: 0 10px 10px;
      padding: 0px 20px 20px;
      border-radius: 43px;

      &:after {
        content: unset;
      }

      &:hover {
        color: white;
      }
    }
  }

  table {
    span.p-highlight {
      background: $c-neutral-off-white !important;
    }
    thead {
      background: $c-neutral-off-white;
      th {
        border-top: 1px solid $c-neutral-20;
        border-bottom: 1px solid $c-neutral-20;
      }
    }
    tbody {
      td {
        padding: 0.5rem;
        span {
          border-radius: 100%;
          height: 25px;
          width: 22px;
          position: relative;
          display: block;
          line-height: 24px;
          text-align: center;
          color: $c-neutral-grey;
        }
      }
    }
  }
}


// .p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: 0 0 0 0.2rem red !important;
// }