@import 'vars';
@import 'mixins';


.fg {
  text-align: center;
  padding: 10px 5px !important;

  @include media_for(desktop) {
    padding: 15px 30px !important;
  }

  label {
    text-transform: capitalize;
    margin: 0 auto;

    @include media_for(desktop) {
      font-size: 12px;
    }
  }

  .form-group {
    margin: 5px auto 0;
    height: 26px;
    padding: 2px;

    @include media_for(desktop) {
      padding: 10px;
      height: 40px;
      margin: 10px auto 0;
    }

    input, select {
      text-align: center;
      text-align-last: center;
      font-size: 8px;

      @include media_for(desktop) {
        font-size: 12px;
      }
    }
  }
}

.form-group {
  height: 60px;
  border: 0.5px solid $c-primary-medium-dark-blue;
  padding: 20px 25px;
  background: $c-white;
  margin: 12px auto;

  &.icon {
    input {
      padding-left: 25px;
    }

    &:before {
      @include abs_center(12px, 12px);
      font-family: 'Font Awesome 5 Free', serif;
      font-weight: 900;
      font-size: 12px;
      line-height: 0;
      z-index: 1;
      color: $c-neutral-40;
      right: inherit;
      top: 15px;
      left: 25px;
      content: '';
    }

    &.i-email::before {
      content: '\f0e0';
    }

    &.i-password::before {
      content: '\f023';
    }
  }

  input, select {
    width: 100%;
    font-size: 15px;
    line-height: 140%;
    margin: 0;
    height: 20px;
    vertical-align: top;
    position: relative;
    border: 0;

    &::placeholder {
      color: $c-neutral-40;
    }
  }

  &.f-error {
    border-color: $c-red;
  }

  .error {
    @include abs_center(100%, 20px);
    top: inherit;
    bottom: -5px;
    left: 30px;
    color: $c-red;
    font-size: 13px;
    text-align: left;
  }
}

.btn {
  margin: 30px auto;
  color: $c-white;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.1em;
  font-weight: bold;
  border-radius: 41px;
  width: 100%;
  padding: 12px 5px;
  background: $c-primary-medium-dark-blue !important;
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;

  &:not(.btn-login) {
    @include media_for(mobile) {
      flex: 0 0 auto;
      max-width: 100%;
      font-size: 9px;
      padding: 10px 5px;
    }
  }

  &:hover {
    background: $c-primary-medium-green !important;
  }

  &.btn-login {
    font-size: 15px;
    padding: 17px 5px;
  }

  &.btn-cancel {
    background: $c-neutral-20 !important;
    color: $c-neutral-grey;
  }

  &.btn-outline {
    margin: 0 auto;
    width: 100%;
    border: 2px solid $c-primary-medium-dark-blue !important;
    background: white !important;
    color: $c-primary-medium-dark-blue;
    padding: 9px 0;

    &:hover {
      background: $c-primary-medium-dark-blue !important;
      color: $c-white;
    }
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $c-primary-medium-dark-blue !important;
}

.p-checkbox .p-checkbox-box.p-highlight { 
  background:  $c-primary-medium-dark-blue !important;
  border-color: $c-primary-medium-dark-blue !important;
}