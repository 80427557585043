@import 'vars';
@import 'mixins';

.menu {
  
  position: fixed;
  width: 100vw;
  height: 64px;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  background: $c-dark-gray;
  align-items: center;
  z-index: 11;

  @include media_for(desktop) {
    display: block;
    width: 140px;
    text-align: center;
    height: calc(100vh - 85px);
    position: absolute;
    left: 0;
    transition: $transition;
  }

  &.show {
    left: 0%;
  }

  .item {
    color: white;
    text-transform: capitalize;
    transition: $transition;
    cursor: pointer;
    height: 20%;
    max-height: 125px;
    display: flex;
    flex-direction: unset;
    margin-top: 0px; 
    align-items: center;
    justify-content: center;
    position: relative;

    @include media_for(desktop) {
      flex-direction: column;
    }

    &:after {
      @include abs_center(5px, 100%);
      content: '';
      background: $c-primary-orange;
      right: inherit;
      opacity: 0;
      transition: $transition;

      @include media_for(mobile) {
        content: unset;
      }
    }

    &:hover, &.active {
      color: $c-primary-orange;
      background: none;
      @include media_for(desktop) {
        background: $c-deep-blue;
      }

      &:after {
        opacity: 1;
      }

      svg path{
        fill: $c-primary-orange;
      }
    }

    .name {
      display: none;
      font-size: 10px;
      margin-top: 2px;
      margin-left: 18px;


      @include media_for(desktop) {
        display: inline;
        margin-left: 0;
      }

      span {
        display: block;
        line-height: 1.2em;

        &:first-of-type {
          margin-top: 10px;
        }
      }
    }

    svg {
      height: 40px;
      width: auto;
      margin-top: 12px;

      @include media_for(mobile) {
        margin-top: 0px;
        display: inline;
      }

      path {
        transition: $transition;
      }
    }
  }
}
