@import 'vars';
@import 'mixins';

header {
  padding: 0 2rem;
  height: 64px;
  width: 100vw;
  position: relative;
  background: #12284C;
  display: flex;
  justify-content: space-between;

  @include media_for(desktop) {
    height: 85px;
  }

  .logo {
    width: 100%;
    max-width: 210px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      margin-right: auto;
      height: 30px;

      @include media_for(mobile) {
        height: 1.5rem;
      }
    }
  }

  .user-data {
    width: 100%;
    max-width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid white;
    padding: 10px 20px;

    .fas {
      position: absolute;
      left: 20px;
      transition: $transition;
      font-size: 18px;

      @include media_for(desktop) {
        display: none;
      }

      &.fa-times {
        color: $c-white;
        z-index: 15;
      }
    }

    .name {
      color: $c-white;
      font-weight: normal;
      font-size: 13px;
      padding: 0px 25px;
      position: relative;

      @include media_for(mobile) {
        display: none;
      }

      &:after {
        @include abs_center(1px, 85px);
        content: '';
        background: white;
        right: inherit;
      }
    }

  }
}
